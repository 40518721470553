export const STORAGE_KEY = {
  LOGIN_AUTH: "9yXw9iwEjl",
};

export enum RequestStatusEnums {
  LOADING = "loading",
  PENDING = "pending",
  SUCCESS = "success",
  FAIL = "fail",
}

export enum RelationshipStatusEnums {
  ACCEPTED = "ACCEPTED",
  REQUEST = "REQUEST",
  REJECTED = "REJECTED",
  EXPIRED = "EXPIRED",
}

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export enum AppPathsEnum {
  LOGIN = "/login",
}

export enum DisplayMode {
  GRID = "grid",
  LIST = "list",
}

export const PAGE_DEFAULT = 1;

export const TOTAL_PAGE_DEFAULT = 1;

export const PAGE_SIZE_DEFAULT = 20;

export const COUNT_DOWN = 60;

export const QR_CODE_EXPIRE_BY_SECONDS = 30;
