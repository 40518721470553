import { SOCKET_URL } from "@/config/api";

export const getWebSocketURL = ({
  webkey,
  fingerprint,
  token,
}: {
  webkey: string;
  fingerprint: string;
  token: string;
}) => {
  return `${SOCKET_URL}/webapp-websocket?webkey=${webkey}&fingerprint=${fingerprint}`;
};
