import React, { memo, useEffect, useState } from "react";

import {
  NotificationNew,
  Forum,
  Home,
  BuildingInsights_3,
  Task,
  MediaLibrary,
  WorkflowAutomation,
  UserMultiple,
  App,
  Apps,
  ScisTransparentSupply,
} from "@carbon/icons-react";
import { NavLink } from "react-router-dom";

import TagaLogoColor from "@/assets/images/taganow-logo-color.svg";
import TGNAvatar from "@/components/TGNAvatar/TGNAvatar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { LIBRARY_URL } from "@/config/api";
import MenuSettingAccountPopup from "@/features/account/screens/MenuSettingAccountPopup";
import { getTokenFromStorage } from "@/features/auth/api/auth";
import NotificationComp from "@/features/notification/screens/Notification/component/Notification";
import { useAppSelector } from "@/redux/store";
import { extractUsername } from "@/utils/extractUsername";

const Header = () => {
  const userInfo = useAppSelector((state) => state.auth.user);
  const [urlStudio, setUrlStudio] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const authInfo = getTokenFromStorage();
    if (!authInfo) return;
    const params = {
      ...JSON.parse(authInfo),
      callback: "/studio",
    };
    setUrlStudio(
      `${LIBRARY_URL}/token/${btoa(JSON.stringify(params))}`
    );
  }, []);

  const navLinks = [
    {
      url: "/",
      end: false,
      icon: <Home size="24" />,
      label: "Trang chủ",
    },
    {
      url: "/group",
      end: false,
      icon: <BuildingInsights_3 size="24" />,
      label: "Nhóm",
    },
    {
      url: "/friends",
      end: false,
      icon: <UserMultiple size="24" />,
      label: "Bạn bè",
    },
  ];
  const workLinks = [
    {
      url: "/task?role=TODO",
      end: false,
      icon: <Task size="24" />,
      label: "Quản lý Công việc",
      des: "Xem thông tin chi tiết và khám phá các công việc của bạn và tất cả mọi người",
    },
    {
      url: "/operation",
      end: false,
      icon: <WorkflowAutomation size="24" />,
      label: "Quản lý Vận hành",
      des: "Xem thông tin chi tiết, tạo quy trình, công việc và khám phá các quy trình của bạn",
    },
    {
      url: "/cloud",
      end: false,
      icon: <MediaLibrary size="24" />,
      label: "Thư viện lưu trữ",
      des: "Xem thông tin chi tiết, lưu trữ tất cả những gì mà bạn muốn",
    },
    {
      url: urlStudio,
      end: true,
      icon: <ScisTransparentSupply size="24" />,
      label: "Taga Studiø",
      des: "Tạo công việc và khám phá các công cụ quản lý hỗ trợ bạn tối ưu trong công việc",
    },
  ];
  return (
    <div className="fixed bg-white border-b border-secondary-100 w-full h-[70px]  z-20">
      <div className="flex justify-between py-2 px-4 w-full h-[70px] items-center">
        <a
          href="/"
          className="w-[220px]"
        >
          <img
            src={TagaLogoColor}
            alt="logo"
            className="h-[30px]  cursor-pointer"
          />
        </a>
        <div className="flex gap-4">
          {navLinks.map((item, index) => {
            return (
              <NavLink
                key={index}
                to={item.url}
                target={item.end ? "blank" : ""}
                className={({ isActive, isPending }) =>
                  isPending
                    ? "pending"
                    : isActive
                    ? "flex items-center py-2.5 px-10 rounded font-medium text-primary bg-primary-50"
                    : "flex items-center py-2.5 px-10 rounded text-secondary-600 gap-2 hover:bg-secondary-100 "
                }
              >
                {item.icon}
              </NavLink>
            );
          })}
        </div>
        <div className="flex items-center gap-4">
          <Popover
            open={isOpen}
            onOpenChange={setIsOpen}
          >
            <PopoverTrigger asChild>
              <div className="p-2.5 rounded hover:bg-secondary-200 cursor-pointer">
                <Apps
                  size="24"
                  className="text-secondary-700"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="p-0 border-none shadow-none w-fit h-fit mx-6">
              <div className="text-sm p-3 bg-white shadow-xl rounded-[8px] text-secondary-600 border border-solid border-secondary-200 w-[480px]  px-6 flex flex-col ">
                <div className="text-2xl">Menu</div>
                <div className="grid p-3 bg-secondary-50 mt-2">
                  <h4 className="uppercase mb-2">Công việc</h4>
                  {workLinks.map((item, index) => {
                    return (
                      <NavLink
                        key={index}
                        to={item.url}
                        target={item.end ? "blank" : ""}
                        className={({ isActive, isPending }) =>
                          isPending
                            ? "pending"
                            : isActive
                            ? "flex gap-3  items-start py-2.5 px-2 rounded font-medium   bg-primary-50"
                            : "flex gap-3  items-start py-2.5 px-2 rounded text-secondary-600  hover:bg-secondary-100 "
                        }
                        onClick={() => setIsOpen(false)}
                      >
                        <div className="p-2 bg-white rounded">{item.icon}</div>
                        <div className="grid gap-1">
                          <h4 className="font-medium text-[16px]">
                            {item.label}
                          </h4>
                          <p className="text-sm text-secondary-500 leading-[16px]">
                            {item.des}
                          </p>
                        </div>
                      </NavLink>
                    );
                  })}
                </div>
                <div className="grid p-3 bg-secondary-50 mt-2">
                  {/* <h4 className="uppercase mb-2">Cộng đồng</h4> */}
                  <p>
                    Quản lý thông tin, quy trình làm việc và con người, tất cả ở
                    cùng một nơi.
                  </p>
                </div>
              </div>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <div className="p-2.5 bg-secondary-100 rounded-full hover:bg-secondary-200 cursor-pointer">
                <NotificationNew
                  size="24"
                  className="text-secondary-700"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="p-0 border-none shadow-none w-fit h-fit mx-6">
              <div className="text-sm p-3 bg-white shadow-xl rounded-[8px] text-secondary-600 border border-solid border-secondary-200 w-[380px]  px-6 flex flex-col ">
                <NotificationComp />
                <a
                  className="text-center underline underline-offset-1 pt-4"
                  href="/notification"
                >
                  Xem tất cả
                </a>
              </div>
            </PopoverContent>
          </Popover>
          <Popover>
            <PopoverTrigger asChild>
              <div className="p-2.5 bg-secondary-100 rounded-full hover:bg-secondary-200 cursor-pointer">
                <Forum
                  size="24"
                  className="text-secondary-700"
                />
              </div>
            </PopoverTrigger>
            <PopoverContent className="p-0 border-none shadow-none w-fit h-fit ml-6">
              <div className="text-sm p-3 bg-white shadow-xl rounded-[8px] text-secondary-600 border border-solid border-secondary-200 w-[280px] h-[400px] flex flex-col ">
                <h5 className=" font-bold">Chats</h5>
                <ul className="flex flex-1 py-4">
                  <li>Chats</li>
                </ul>
                <a
                  className="text-center underline underline-offset-1"
                  href="/chats"
                >
                  Xem tất cả
                </a>
              </div>
            </PopoverContent>
          </Popover>

          {userInfo && (
            <Popover>
              <PopoverTrigger asChild>
                <div className="flex items-center">
                  <TGNAvatar
                    className="cursor-pointer"
                    src={userInfo.photo}
                    name={
                      userInfo.name || extractUsername(userInfo.email || "")
                    }
                    size="40"
                    round
                  />
                </div>
              </PopoverTrigger>
              <PopoverContent className="p-0 border-none shadow-none w-fit h-fit ml-6">
                <MenuSettingAccountPopup />
              </PopoverContent>
            </Popover>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Header);
