import React, { useEffect, useMemo, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";

import { LIBRARY_URL } from "@/config/api";
import { getTokenFromStorage } from "@/features/auth/api/auth";
import { cn } from "@/lib/utils";
import { useAppSelector } from "@/redux/store";

const GroupLibraryScreen = () => {
  const { data: groupInfo } = useAppSelector((state) => state.group);
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const [url, setUrl] = useState<string>();
  const [isShowOverlay, setIsShowOverlay] = useState<boolean>(false);
  const [postMessage, setPostMessage] = useState<MessageEvent>();

  const isShareWithMe = useMemo(
    () => location.pathname.includes("/library/share-with-me"),
    [location.pathname]
  );

  useEffect(() => {
    if (!groupInfo) return;
    const authInfo = getTokenFromStorage();
    if (!authInfo) return;
    const params = {
      ...JSON.parse(authInfo),
      groupId: id,
      libraryId: groupInfo.libraryId,
      callback: getCallbackUrl(),
    };
    setUrl(`${LIBRARY_URL}/token/${btoa(JSON.stringify(params))}`);
  }, [location.pathname]);

  // Listener from iframe
  useEffect(() => {
    window.addEventListener("message", (ev: MessageEvent) => {
      setPostMessage(ev);
    });
  }, []);

  useEffect(() => {
    if (!postMessage) return;
    if (typeof postMessage.data !== "object") return;
    if (!postMessage.data.type) return;
    if (
      postMessage.data?.message === "updateURL" &&
      postMessage.data?.payload?.pathname
    ) {
      const pathnameArray: string[] =
        postMessage.data?.payload?.pathname.split("/");
      if (pathnameArray[1] === "taga-library" && pathnameArray.length === 4) {
        if (isShareWithMe) {
          navigate(
            `/group/${id}/library/share-with-me?libraryId=${pathnameArray[3]}`
          );
        } else {
          navigate(
            `/group/${id}/library?type=${pathnameArray[2]}&libraryId=${pathnameArray[3]}`
          );
        }
      }
    } else if (postMessage.data?.message === "showDialog" && !isShowOverlay) {
      setIsShowOverlay(true);
    } else if (postMessage.data?.message === "hiddenDialog") {
      setIsShowOverlay(false);
    }
  }, [postMessage]);

  // Load URL for iframe
  // useEffect(() => {
  // }, [groupInfo]);

  const getCallbackUrl = () => {
    if (!groupInfo) return "";
    const queryString = window.location.search;
    let callback = ``;
    const urlParams = new URLSearchParams(queryString);
    if (isShareWithMe) {
      callback = `/taga-library/share/${
        urlParams.get("libraryId") || groupInfo.libraryId
      }`;
    } else {
      if (urlParams.get("type") && urlParams.get("libraryId")) {
        callback = `/taga-library/${urlParams.get("type")}/${urlParams.get(
          "libraryId"
        )}`;
      } else {
        callback = `/taga-library/common/${groupInfo.libraryId}`;
      }
    }
    return callback;
  };

  return (
    <>
      {isShowOverlay ? (
        <div
          className={cn(
            "fixed inset-0 z-50 bg-background/80 backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
            "w-[344px]"
          )}
        ></div>
      ) : null}
      <div className="h-screen w-full">
        <iframe
          id="iframeLibrary"
          className="h-full w-full"
          title={"Thư viện"}
          src={url}
        ></iframe>
      </div>
    </>
  );
};

export default GroupLibraryScreen;
