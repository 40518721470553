import React, { memo, useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { LoadingIcon } from "@/assets/icons";
import { Toaster } from "@/components/ui/sonner";
import { Toaster as CustomToaster } from "@/components/ui/toaster";
import { API_URL } from "@/config/api";
import { AppPathsEnum } from "@/constants/app.constants";
import {
  getCurrentUsername,
  removeTokenFromStorage,
} from "@/features/auth/api/auth";
import {
  authAction,
  getLibraryIdThunk,
} from "@/features/auth/redux/auth.slice";
import { notificationAction } from "@/features/notification/redux/notification.slice";
import { INotification } from "@/features/notification/types/notification.type";
import { getNotificationText } from "@/features/notification/utils/notificationText";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import RoutesWrapper from "@/routes/RoutesWrapper";
import { formatDistance } from "@/utils/date";

import InsideLayout from "./InsideLayout";
import TGNAvatar from "../TGNAvatar/TGNAvatar";
import TGNLoading from "../TGNLoading/TGNLoading";
import { Button } from "../ui/button";
import { Dialog, DialogContent } from "../ui/dialog";
import { useToast } from "../ui/use-toast";

const Default = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const dispatch = useAppDispatch();
  const { notifications } = useAppSelector((state) => state.notification);
  const loadingGlobal = useAppSelector((state) => state.common.loading);
  const location = useLocation();
  const { user } = useAppSelector((state) => state.auth);
  const { toast } = useToast();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userInfo = await getCurrentUsername();

        if (!userInfo) {
          throw new Error("Unauthorized"); // Handle invalid user
        }

        // If the user is on the login page but already logged in, redirect to home
        if (location.pathname === AppPathsEnum.LOGIN) {
          navigate("/"); 
        }

        dispatch(authAction.setUserData(userInfo));
        dispatch(getLibraryIdThunk());
      } catch (error: any) {
        console.error("Auth error:", error);

        if (error?.response?.status === 403) {
          setShowLoginDialog(true); // Show login dialog
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);
  useEffect(() => {
    if (!user?.id) {
      return;
    }
    const urlEndpoint =
      API_URL + "/api/v1/notification/event?userId=" + user?.id;
    const eventSource = new EventSource(urlEndpoint);
    function listenEvent(event: MessageEvent) {
      const decode = event.data.replace(/\+/g, " ");
      // Decode the URI component
      const decodedText = decodeURIComponent(decode);
      const item = JSON.parse(decodedText) as INotification;
      toast({
        variant: "default",
        description: (
          <div>
            {item.photoUrl ? (
              <img
                src={item.photoUrl}
                alt=""
                className="w-10 h-10 object-cover"
              />
            ) : (
              <TGNAvatar
                size="40"
                name={item.sender.name}
              />
            )}
            <div>
              <div
                className="text-sm mb-1"
                dangerouslySetInnerHTML={{
                  __html: getNotificationText(item),
                }}
              ></div>
              <p className="text-xs text-secondary-600">
                {formatDistance(item.createdAt)} trước
              </p>
            </div>
          </div>
        ),
      });
      if (!notifications.find((notify) => notify.id === item.id)) {
        dispatch(notificationAction.addNotification(item));
      }
    }
    eventSource.addEventListener("notificationEvent", listenEvent);
    eventSource.addEventListener("error", () => {
      eventSource.close();
    });
    return () => {
      eventSource.removeEventListener("notificationEvent", listenEvent);
      eventSource.removeEventListener("error", () => {
        eventSource.close();
      });
      eventSource.close();
    };
  }, [user?.id, notifications, dispatch]);

  const handleLoginAgain = () => {
    removeTokenFromStorage();
    setShowLoginDialog(false);
    navigate(AppPathsEnum.LOGIN);
  };

  return (
    <>
      {loading ? (
        <TGNLoading
          isFullScreen={true}
          size="large"
        />
      ) : (
        <div className="app min-h-screen">
          {/* Global Loading Dialog */}
          <Dialog open={loadingGlobal.show}>
            <DialogContent className="p-6 text-center max-w-[400px] gap-2">
              <LoadingIcon className="animate-spin duration-[3000ms] mx-auto" />
              <p className="text-secondary-900 text-lg font-semibold mt-2">
                {loadingGlobal.title}
              </p>
              <p className="text-secondary-600 text-sm">
                {loadingGlobal.description}
              </p>
            </DialogContent>
          </Dialog>

          {/* Login Again Dialog on 403 */}
          <Dialog open={showLoginDialog}>
            <DialogContent className="p-6 text-center max-w-[400px] gap-4">
              <h2 className="text-lg font-semibold">Phiên đăng nhập hết hạn</h2>
              <p className="text-sm text-secondary-600">
                Vui lòng đăng nhập lại để tiếp tục sử dụng ứng dụng.
              </p>
              <Button
                onClick={handleLoginAgain}
                variant="primary"
              >
                Đăng nhập lại
              </Button>
            </DialogContent>
          </Dialog>

          <Toaster />
          <CustomToaster />
          <InsideLayout>
            <RoutesWrapper />
          </InsideLayout>
        </div>
      )}
    </>
  );
};

export default memo(Default);
