import { DefaultEventsMap } from "@socket.io/component-emitter";
import { Socket, io } from "socket.io-client";
import { v4 as uuidv4 } from "uuid";

import { SIGNALING_URL } from "@/config/api";

import {
  AddChatParams,
  MindmapItemDef,
  MindmapNodeSocketDef,
  MindmapSocketResponse,
  RequestUploadResponse,
  RevisionsType,
  UpdateFileParams,
} from "../mindmap";

type Listener = (data: MindmapSocketResponse) => void;

export const socketMindmap = (token: string) => {
  let listener: null | Listener = null;
  let socketIO: Socket<DefaultEventsMap, DefaultEventsMap>;
  const promiseQueue: Record<
    string,
    (value: RequestUploadResponse | PromiseLike<RequestUploadResponse>) => void
  > = {};
  return {
    connect: () => {
      socketIO = io(SIGNALING_URL + "/signal");
      socketIO.on("connect", () => {
        socketIO.emit("register", { token: token });
        socketIO.on("signal", (data: MindmapSocketResponse) => {
          if (listener) {
            listener(data as MindmapSocketResponse);
            if (data.promiseId) {
              promiseQueue[data.promiseId] &&
                promiseQueue[data.promiseId](data as any); // eslint-disable-line
              delete promiseQueue[data.promiseId];
            }
          }
        });
        socketIO.on("disconnect", () => {
          console.log("close");
        });
      });
    },
    getData: () => {
      socketIO.emit("signal", {
        token: token,
        command: "GET_DATA",
      });
    },
    addTask: (task: MindmapItemDef) => {
      socketIO.emit("signal", {
        token: token,
        command: "ADD_TASK",
        payload: {
          ...task,
          data: JSON.stringify({
            description: task.description,
          }),
          permissions: [
            ["nguyenson7620000@gmail.com", "owner"],
            ["dinhdb.android@gmail.com", "owner"],
          ],
        },
      });
    },
    updateTask: (task: MindmapNodeSocketDef) => {
      const { id, name, parentId, permissions } = task;
      socketIO.emit("signal", {
        token: token,
        command: "UPDATE_TASK",
        payload: {
          id,
          name,
          parentId,
          permissions,
          data: JSON.stringify({
            description: task.description,
          }),
        },
      });
    },
    updateTasks: (tasks: MindmapNodeSocketDef[]) => {
      const data = tasks.map((task) => {
        const { id, name, parentId, permissions, description } = task;
        return {
          id,
          name,
          parentId,
          permissions,
          data: JSON.stringify({
            description,
          }),
        };
      });
      socketIO.emit("signal", {
        token: token,
        command: "UPDATE_TASKS",
        payload: data,
      });
    },
    updateData: (data: UpdateFileParams) => {
      socketIO.emit("signal", {
        token: token,
        command: "UPDATE_DATA",
        payload: { ...data, extensionData: JSON.stringify(data.extensionData) },
      });
    },
    requestDownload: (revision: RevisionsType) => {
      socketIO.emit("signal", {
        token: token,
        command: "REQUEST_DOWNLOAD",
        payload: { ...revision },
      });
    },
    getChats: (stepId: string) => {
      socketIO.emit("signal", {
        token: token,
        command: "GET_CHAT",
        payload: {
          stepId,
        },
      });
    },
    addChat: ({ stepId, value }: AddChatParams) => {
      socketIO.emit("signal", {
        token: token,
        command: "ADD_CHAT",
        payload: { stepId, value: JSON.stringify(value) },
      });
    },
    requestUpload: (
      files: {
        name: string;
        type: string;
        size: number;
      }[]
    ): Promise<RequestUploadResponse> => {
      const promiseId = uuidv4();
      socketIO.emit("signal", {
        token: token,
        promiseId,
        command: "REQUEST_UPLOAD",
        payload: [...files],
      });
      return new Promise(
        (
          resolve: (
            value: RequestUploadResponse | PromiseLike<RequestUploadResponse>
          ) => void
        ) => {
          promiseQueue[promiseId] = resolve;
        }
      );
    },
    listener: (callback: Listener) => {
      listener = callback;
    },
    removeAllListener: () => {
      listener = null;
      socketIO.disconnect();
    },
    off: () => {
      socketIO.off();
    },
  };
};

export type MindmapIOType = ReturnType<typeof socketMindmap>;
